import React from 'react'
import styled, { withTheme } from 'styled-components'
import translate from '../../../../../../modules/translate'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import Select from '../../../../../../components/Select'

const StyledSelect = styled(Select)`
  width: ${(props) => props.theme.sizes.col6};
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    width: ${(props) => props.theme.tablet.sizes.col6};
    min-width: 70%;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    width: ${(props) => props.theme.mobile.sizes.col6};
    min-width: 100%;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: ${(props) => props.theme.tablet.breakPoint + 'px'}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
  }
  @media (max-width: ${(props) => props.theme.mobile.breakPoint + 'px'}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 120px;
  }
`

let wrapperRef = React.createRef()

const RowSelect = ({ type, zone, zones, types, updateType, updateZone, ...props }) => (
  <Wrapper ref={wrapperRef} {...props}>
    <StyledSelect
      menuShouldScrollIntoView
      value={type}
      onChange={(selectedOption) => updateType(selectedOption ? selectedOption.value : undefined)}
      placeholder={translate('traffic_selection_type')}
      rows={types.length}
      noResultsText={translate('traffic_selection_type_no_data')}
      values={types.map(({ value, label }) => ({
        value: value,
        label: label,
      }))}
    />
    <StyledSelect
      menuShouldScrollIntoView
      value={zone}
      onChange={(selectedOption) => updateZone(selectedOption ? selectedOption.value : undefined)}
      placeholder={translate('traffic_selection_zone')}
      noResultsText={translate('traffic_selection_zone_no_data')}
      rows={zones.length}
      clearable
      values={zones.map(({ value, label }) => ({
        value: value,
        label: label,
      }))}
    />
  </Wrapper>
)

RowSelect.propTypes = {
  zone: PropTypes.string,
  type: PropTypes.string,
  updateType: PropTypes.func.isRequired,
  updateZone: PropTypes.func.isRequired,
  zones: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
}

export default withTheme(RowSelect)
